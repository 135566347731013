import React, { useState } from "react";
import { useCart } from "./CartContext";
import BackButton from "./BackButton";
import Modal from "./Modal";
import PaymentForm from "./PaymentForm";
import AddressForm from "./AddressForm";
import verifyAddress from "./verifyAddress";

function CartPage() {
  const { cart, removeFromCart, updateQuantity } = useCart();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const handleRemove = (index) => {
    setItemToRemove(index);
    setIsModalOpen(true);
  };

  const confirmRemove = () => {
    removeFromCart(itemToRemove);
    setIsModalOpen(false);
  };

  const handleQuantityChange = (index, increment) => {
    const newQuantity = cart[index].quantity + increment;
    if (newQuantity <= 0) {
      handleRemove(index);
    } else {
      updateQuantity(index, newQuantity);
    }
  };

  const handleInputChange = (index, value) => {
    const newQuantity = Math.max(1, parseInt(value) || 1);
    updateQuantity(index, newQuantity);
  };

  const total = cart
    .reduce((acc, item) => acc + parseFloat(item.price) * item.quantity, 0)
    .toFixed(2);

  const handleVerifyAddress = async (address) => {
    return await verifyAddress(address);
  };

  return (
    <div className="bg-background main-container min-h-screen py-10">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-32 2xl:px-64">
        <BackButton />
        <div className="bg-card p-6 shadow-big grid grid-cols-1 lg:grid-cols-3 gap-6">
          {cart.length > 0 ? (
            <>
              <div className="lg:col-span-2">
                <h2 className="text-2xl text-navbar font-bold mb-6">
                  Your Cart
                </h2>
                <div className="space-y-4 divide-y">
                  {cart.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row justify-between items-center p-2"
                    >
                      <div className="flex items-center space-x-4 sm:space-x-4 pt-5 w-full">
                        {item.image && (
                          <img
                            src={item.image}
                            alt={item.name}
                            className="w-24 h-24 object-cover rounded"
                          />
                        )}
                        <div className="flex flex-col items-start">
                          <h3 className="text-lg font-bold">{item.name}</h3>
                          <div className="flex items-center border border-gray-300 rounded mt-2">
                            <button
                              onClick={() => handleQuantityChange(index, -1)}
                              className="px-3 py-1 border-r border-gray-300 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              min="1"
                              value={item.quantity}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              className="w-12 text-center bg-white dark:bg-gray-700 border-none focus:outline-none focus:ring-accent appearance-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            />
                            <button
                              onClick={() => handleQuantityChange(index, 1)}
                              className="px-3 py-1 border-l border-gray-300 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between w-full sm:w-auto mt-4 sm:mt-0 space-y-2 sm:space-y-0">
                        <p className="text-lg pl-5 md:pr-8 lg:pr-16">
                          ${parseFloat(item.price).toFixed(2)}
                        </p>
                        <button
                          onClick={() => handleRemove(index)}
                          className="text-gray-700 hover:text-red-500 sm:ml-4"
                        >
                          <i className="gg-trash"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:col-span-1 bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-bold mb-4">Order Summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Subtotal</span>
                    <span>${total}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Tax</span>
                    <span>$0</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Shipping</span>
                    <span>$10</span>
                  </div>
                  <div className="flex justify-between font-bold border-t pt-2 mt-2">
                    <span>Total</span>
                    <span>${(parseFloat(total) + 10).toFixed(2)}</span>
                  </div>
                </div>
                <div className="mt-4">
                  <button className="w-full bg-navbar text-white py-2 rounded hover:bg-accent transition-colors duration-100">
                    Confirm payment
                  </button>
                </div>
                <div className="mt-2">
                  <button className="w-full bg-gray-200 text-gray-700 py-2 rounded hover:bg-gray-300 transition-colors duration-100">
                    Continue Shopping
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="lg:col-span-3 flex justify-center items-center">
              <p className="text-xl font-bold">Your cart is empty</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmRemove}
        message="Are you sure you want to remove this item from the cart?"
      />
    </div>
  );
}

export default CartPage;
