// src/components/About.js
import React from 'react';
import PageLayout from '../PageLayout';

function MaintenancePage(debugInfo) {
  return (
    <PageLayout>
      <div className="bg-card p-6 shadow-big">
        <h2 className="text-4xl text-navbar font-bold mb-12 mt-12 flex justify-center">
          Maintenance ahead!
        </h2>
        <div className="text-description mb-6 text-center mr-10 ml-10 lg:mr-12 lg:ml-12 xl:mr-24 xl:ml-24">
          <p className="mb-12">
            Welcome,
            <br />
            <br />
            We're undergoing maintenance, wait for the updates!
          </p>
        </div>
      </div>
    </PageLayout>
  );
}

export default MaintenancePage;
