// src/services/astrologicalService.js
import api from './api';

const astrologicalService = {
  createAstrologicalSuggestions: async (csrfToken) => {
    try {
      const response = await api.get('/daily-forecast/', {
        responseType: 'blob',
        withCredentials: true,
        headers: {
          'X-CSRFToken': csrfToken,
        },
      });

      // Convert Blob to text, then parse as JSON
      const textData = await response.data.text();
      const jsonData = JSON.parse(textData);

      return jsonData;
    } catch (error) {
      console.error('Error creating astrological subject:', error);
      throw error;
    }
  },
};

export default astrologicalService;
