import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import topicService from './Services/topicService';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = useMemo(
    () => location.pathname.split('/').filter((x) => x),
    [location.pathname]
  );
  const navigate = useNavigate();

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBreadcrumbHierarchy = async () => {
      if (pathnames.length === 0) {
        setBreadcrumbItems([]);
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const lastSlug = pathnames[pathnames.length - 1];
        const response = await topicService.getBreadcrumbHierarchy(lastSlug);
        const hierarchy = response;

        const items = hierarchy.map((item, index) => ({
          name: item.name,
          path: `/${hierarchy
            .slice(0, index + 1)
            .map((i) => i.slug)
            .join('/')}`,
        }));

        setBreadcrumbItems(items);
      } catch (error) {
        //console.error('Error fetching breadcrumb hierarchy:', error);
        if (error.response && error.response.status === 404) {
          // Redirect to home page if topic is not found
          navigate('/topics');
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBreadcrumbHierarchy();
  }, [pathnames]);

  return (
    <nav className="flex text-gray-700 text-sm mb-4 bg-white py-3 px-4 rounded-lg shadow-md">
      <Link to="/topics" className="text-navbar hover:text-accent font-medium ">
        Topics
      </Link>
      {loading ? (
        <span className="mx-2 text-gray-400">/</span>
      ) : (
        breadcrumbItems.map((item, index) => (
          <span key={item.path} className="flex items-center">
            <span className="mx-2 text-gray-400">/</span>
            {index === breadcrumbItems.length - 1 ? (
              <span className="text-gray-600 font-semibold ">{item.name}</span>
            ) : (
              <Link to={item.path} className="text-navbar hover:text-accent ">
                {item.name}
              </Link>
            )}
          </span>
        ))
      )}
    </nav>
  );
};

export default Breadcrumbs;
