// src/App.js
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useLocation,
} from 'react-router-dom';
import Home from './components/Pages/Home';
import TopicsHomePage from './components/Pages/TopicsHomePage';
import TopicPage from './components/Pages/TopicPage';
import About from './components/Pages/About';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Shop from './components/Pages/Shop';
import Categories from './components/Categories';
import ProductPage from './components/Pages/ProductPage';
import CartPage from './components/CartPage';
import SigilPage from './components/Pages/SigilPage';
import ForecastPage from './components/Pages/ForecastPage';
import RoadmapPage from './components/Pages/RoadmapPage';
import Analytics from './components/Analytics';
import TrackPageView from './components/TrackPageView';
//import AstrologicalPage from './components/Pages/AstrologicalPage';
import MaintenancePage from './components/Pages/MaintenancePage';
import maintenanceService from './components/Services/maintenanceService';
import { useGoogleAnalytics } from './components/UseGoogleAnalytics';
import { Helmet } from 'react-helmet';
import './App.css';

const AstrologicalPage = lazy(() =>
  import('./components/Pages/AstrologicalPage')
);

const AppContent = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [debugInfo, setDebugInfo] = useState(null);
  const isFirstRender = useRef(true);

  const location = useLocation();
  const { trackPageView } = useGoogleAnalytics();

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    trackPageView(location.pathname + location.search);
  }, [location, trackPageView]);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const data = await maintenanceService.getMaintenanceStatus();
        setIsMaintenanceMode(data.maintenance_mode);
        setIsAllowed(data.allowed);
        setDebugInfo(data);
      } catch (error) {
        console.error('Error in fetchMaintenanceStatus:', error);
        setIsMaintenanceMode(true);
        setIsAllowed(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  const TopicWrapper = () => {
    const params = useParams();
    const fullPath = params['*']; // This captures the entire nested path after the root
    return <TopicPage path={fullPath} />;
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <>
      <Helmet>
        <title>Hederum</title>
        <meta
          name="description"
          content="An all-in-one website with informations, sigil creation, astrological reading and more to come ..."
        />
        <meta
          name="keywords"
          content="Hederum, occult, esoteric, astrology, tarot, magick, herbalism"
        />
      </Helmet>

      <div className="flex flex-col min-h-screen relative">
        <div className="pillar-left">
          <div className="pillar-top"></div>
          <div className="pillar-middle"></div>
        </div>

        {/* Right Pillar */}
        <div className="pillar-right">
          <div className="pillar-top"></div>
          <div className="pillar-middle"></div>
        </div>
        <header className="header">
          <Navbar maintenance={!isAllowed} />
        </header>
        <main className="flex-grow">
          {isMaintenanceMode && !isAllowed ? (
            <MaintenancePage debugInfo={debugInfo} />
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/*" element={<TopicWrapper />} />
              <Route path="/topics" element={<TopicsHomePage />} />
              {/* fix this route pls.. */}
              <Route path="/about" element={<About />} />
              <Route path="/roadmap" element={<RoadmapPage />} />
              {/*<Route path="/shop" element={<Categories />} />
            <Route path="/shop/:slug" element={<Shop />} />*/}
              <Route path="/products/:slug" element={<ProductPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/create-sigil" element={<SigilPage />} />
              <Route path="/forecast" element={<ForecastPage />} />
              <Route
                path="/astrological-reading"
                element={
                  <Suspense>
                    <AstrologicalPage />
                  </Suspense>
                }
              />
            </Routes>
          )}
        </main>
        <Footer />
      </div>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
