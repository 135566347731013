import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Breadcrumbs from '../Breadcrumbs';
import PageLayout from '../PageLayout';
import topicService from '../Services/topicService';
import TopicCard from '../TopicCard';
import CustomShareButtons from '../CustomShareButtons';

// Custom SVG icons
const RobotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="11" width="18" height="10" rx="2" />
    <circle cx="12" cy="5" r="2" />
    <path d="M12 7v4" />
    <line x1="8" y1="16" x2="8" y2="16" />
    <line x1="16" y1="16" x2="16" y2="16" />
  </svg>
);

const CheckCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <polyline points="22 4 12 14.01 9 11.01" />
  </svg>
);

function TopicPage() {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const slugs = useMemo(() => path.split('/').filter((slug) => slug), [path]);

  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(true);

  const scrollToSubtopics = () => {
    const subTopics = document.getElementById('subtopics-section');
    if (subTopics) subTopics.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchTopic = async () => {
      setLoading(true);
      if (slugs.length > 0) {
        const lastSlug = slugs[slugs.length - 1];
        try {
          const response = await topicService.getTopicBySlug(lastSlug);
          setTopic(response);
          setLoading(false);
        } catch (error) {
          //console.error('Error fetching topic:', error);
          if (error.response && error.response.status === 404) {
            // Redirect to home page if topic is not found
            navigate('/');
          } else {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    };
    fetchTopic();
  }, [slugs, navigate]);

  const generatePath = (subtopic) => {
    return `${path}/${subtopic.slug}`;
  };

  return (
    <PageLayout>
      <Breadcrumbs />
      <div
        className={`bg-card p-6 shadow-big ${loading ? 'loading' : 'loaded'}`}
      >
        {loading ? (
          <div>Loading...</div>
        ) : topic ? (
          <>
            <Helmet>
              {/* Open Graph Meta Tags */}
              <meta property="og:title" content={topic.title} />
              <meta property="og:description" content={topic.description} />
              <meta property="og:image" content={topic.image} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content="article" />

              {/* Twitter Card Meta Tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={topic.title} />
              <meta name="twitter:description" content={topic.description} />
              <meta name="twitter:image" content={topic.image} />
            </Helmet>
            <h2 className="text-3xl text-navbar font-bold mb-6 flex flex-col md:flex-row justify-between items-start">
              {/* Left Section: Topic name, AI Generated, and Reviewed */}
              <div>
                {/* Topic Name */}
                <div>{topic.name}</div>

                {/* AI Generated Badge */}
                {topic.ai_generated && (
                  <div className="mt-1 text-sm font-normal text-gray-500 flex items-center">
                    <RobotIcon />
                    <span className="ml-1">AI Generated</span>
                  </div>
                )}

                {/* Reviewed Badge */}
                {topic.reviewed && (
                  <div className="mt-1 text-sm font-normal text-gray-500 flex items-center">
                    <CheckCircleIcon />
                    <span className="ml-1">Reviewed</span>
                  </div>
                )}
              </div>

              {/* Right Section: Share Buttons */}
              <CustomShareButtons
                url={window.location.href} // The current URL
                title={`Check out this topic: ${topic.name}`} // Custom title for sharing
              />

              {/* Button (Responsive) */}
              <button
                className="bg-navbar text-white text-sm md:text-base py-1.5 px-3 rounded hover:bg-accent mt-4 md:mt-0"
                onClick={scrollToSubtopics}
              >
                Go to Subtopics
              </button>
            </h2>

            {/* Topic Description */}
            <div
              className="text-description mb-6 sm:text-base md:text-sm"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(topic.html_content, {
                  ADD_ATTR: ['target'],
                }),
              }}
            />

            {/* Subtopics Section */}
            {topic.subtopics.length > 0 && (
              <div>
                <h3
                  id="subtopics-section"
                  className="text-2xl text-navbar font-semibold mb-4 flex justify-center"
                >
                  Subtopics
                </h3>
                <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mt-6">
                  {topic.subtopics.map((subtopic) => (
                    <TopicCard
                      key={subtopic.id}
                      id={subtopic.id}
                      slug={subtopic.slug}
                      name={subtopic.name}
                      image={subtopic.image}
                    />
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <div>No topic found</div>
        )}
      </div>
    </PageLayout>
  );
}

export default TopicPage;
