// src/services/topicService.js
import api from './api';

const topicService = {
  getTopicBySlug: async (slug) => {
    try {
      const response = await api.get(`/topics/${slug}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllTopics: async () => {
    try {
      const response = await api.get('/topics/');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  searchTopics: async (query) => {
    try {
      const response = await api.get(`search/?q=${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getBreadcrumbHierarchy: async (slug) => {
    try {
      const response = await api.get(`/topics/hierarchy/${slug}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching breadcrumb hierarchy:', error);
      throw error;
    }
  },

  getHomePageTopics: async () => {
    try {
      const response = await api.get('/topics/homepage/');
      return response.data;
    } catch (error) {
      console.error('Error fetching homepage topics:', error);
    }
  },
};

export default topicService;
