import React from 'react';

const OccultAstrologyTheme = ({ data }) => {
  const planetsList = [
    'Sun',
    'Moon',
    'Mercury',
    'Venus',
    'Mars',
    'Jupiter',
    'Saturn',
    'Uranus',
    'Neptune',
    'Pluto',
  ];

  const planetSymbols = {
    Sun: '☉',
    Moon: '☽',
    Mercury: '☿',
    Venus: '♀',
    Mars: '♂',
    Jupiter: '♃',
    Saturn: '♄',
    Uranus: '♅',
    Neptune: '♆',
    Pluto: '♇',
  };

  const getHouseNumber = (house) => {
    const houseMap = {
      First_House: '1st',
      Second_House: '2nd',
      Third_House: '3rd',
      Fourth_House: '4th',
      Fifth_House: '5th',
      Sixth_House: '6th',
      Seventh_House: '7th',
      Eighth_House: '8th',
      Ninth_House: '9th',
      Tenth_House: '10th',
      Eleventh_House: '11th',
      Twelfth_House: '12th',
    };
    return houseMap[house] || house;
  };

  if (data === null) return null;

  const getPlanetInfo = (planetName) => {
    const planet = data[planetName.toLowerCase()];
    return {
      symbol: planetSymbols[planetName],
      sign: planet.emoji,
      house: getHouseNumber(planet.house),
      retrograde: planet.retrograde,
    };
  };

  return (
    <div className="flex justify-center w-full">
      <div className="grid grid-cols-2 gap-x-4 gap-y-2 pt-2 w-3/5 max-w-xl">
        {planetsList.map((planet) => {
          const info = getPlanetInfo(planet);
          return (
            <div
              key={planet}
              className="flex items-center justify-between py-1 px- border-b"
            >
              <div className="flex items-center space-x-2">
                <span className="text-xl w-6 text-center">{info.symbol}</span>
                <span className="text-lg">{info.sign}</span>
              </div>
              <div className="flex items-center">
                {info.retrograde && (
                  <span className="text-red-500 text-xs font-bold">R</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OccultAstrologyTheme;
