import React, { useState } from 'react';
import SigilCanvas from '../SigilCanvas';
import PageLayout from '../PageLayout';

const SigilPage = () => {
  const [phrase, setPhrase] = useState('');
  const [formType, setFormType] = useState('formA'); // Default to 'formA'
  const [randomRange, setRandomRange] = useState(0.0); // Default random range
  const [lineWidth, setLineWidth] = useState(0.05);
  const [outLineWidth, setOutLineWidth] = useState(0.05);
  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    setPhrase(e.target.value);
  };

  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
  };

  const handleRandomRangeChange = (e) => {
    setRandomRange(parseFloat(e.target.value));
  };

  const handleLineWidthChange = (e) => {
    setLineWidth(parseFloat(e.target.value));
  };

  const handleOutLineWidthChange = (e) => {
    setOutLineWidth(parseFloat(e.target.value));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <PageLayout>
      <div className="bg-card p-6 shadow-big">
        <h2 className="text-3xl text-navbar font-bold mb-6 flex justify-center">
          Create a Sigil
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
          <input
            type="text"
            value={phrase}
            onChange={handleChange}
            placeholder="Enter your phrase"
            className="p-2 border border-gray-300 rounded w-2/3 md:w-1/3"
          />
          <select
            value={formType}
            onChange={handleFormTypeChange}
            className="p-2 border border-gray-300 rounded w-25 md:w-25"
          >
            <option value="formA">Circle</option>
            <option value="formB">Square</option>
          </select>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 mt-5 mb-4 md:mt-2 md:space-y-0 md:space-x-4">
          <label className="block text-sm font-medium text-gray-700">
            Randomness Range: {randomRange.toFixed(2)}
          </label>
          <input
            type="range"
            min="0"
            max="0.5"
            step="0.01"
            value={randomRange}
            onChange={handleRandomRangeChange}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 w-2/3 md:w-1/4"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 mt-5 mb-4 md:mt-2 md:space-y-0 md:space-x-4">
          <label className="block text-sm font-medium text-gray-700">
            Line width: {lineWidth.toFixed(2)}
          </label>
          <input
            type="range"
            min="0.01"
            max="0.1"
            step="0.01"
            value={lineWidth}
            onChange={handleLineWidthChange}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 w-2/3 md:w-1/4"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 mt-5 mb-4 md:mt-2 md:space-y-0 md:space-x-4">
          <label className="block text-sm font-medium text-gray-700">
            Outline width: {outLineWidth.toFixed(2)}
          </label>
          <input
            type="range"
            min="0.01"
            max="0.1"
            step="0.01"
            value={outLineWidth}
            onChange={handleOutLineWidthChange}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 w-2/3 md:w-1/4"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center space-y-4 mt-5 mb-4 md:mt-2 md:space-y-0 md:space-x-4">
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </div>
        <SigilCanvas
          phrase={phrase}
          formType={formType}
          randomRange={randomRange}
          lineWidth={lineWidth}
          outLineWidth={outLineWidth}
          image={image}
        />
      </div>
    </PageLayout>
  );
};

export default SigilPage;
