import React from 'react';
import { Link } from 'react-router-dom';

const TopicCard = ({ id, slug, name, image }) => {
  return (
    <Link key={id} to={`/${slug}`} className="text-topic text-lg font-medium">
      <li className="topic-card border border-border/90 transition-all duration-500 ease-in-out hover:shadow-lg rounded flex flex-col items-center justify-center h-48 relative overflow-hidden group">
        <div
          className="absolute inset-0 bg-gradient-to-t from-accent/50 via-transparent to-transparent 
                          transition-all duration-500 ease-in-out 
                          group-hover:from-accent/70 group-hover:via-accent/20 group-hover:to-transparent"
        ></div>
        {image && (
          <img
            src={image}
            alt={name}
            loading="lazy"
            className="absolute inset-0 w-full h-full object-cover object-center 
                         transition ease-in-out group-hover:scale-110 duration-300"
          />
        )}
        <h3
          className="absolute z-10 text-center
     transition-all duration-500 ease-in-out transform
     top-0 translate-y-0 group-hover:-translate-y-1/2 group-hover:top-1/2
     bg-black/50 text-navbar/80
     flex items-end justify-center px-3 py-1 whitespace-nowrap overflow-hidden"
        >
          {name}
        </h3>
      </li>
    </Link>
  );
};

export default TopicCard;
