import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Moon, Sun, Star, Book, ShoppingBag, Map } from 'lucide-react';
import PageLayout from '../PageLayout';
import externalApiService from '../Services/externalApiService';
import OccultAstrologyTheme from '../CurrentAstrologyTheme';
import DynamicMoonPhaseWidget from '../MoonPhase';
import '../Style/Home.css';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [astrologyData, setAstrologyData] = useState(null);

  useEffect(() => {
    const fetchRapidApiAstrology = async () => {
      try {
        const response = await externalApiService.getRapidApiAstrology();
        setAstrologyData(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching rapid api astrology:', error);
        setLoading(false);
      }
    };
    fetchRapidApiAstrology();
  }, []);

  const navigationItems = [
    { name: 'Topics', route: '/topics', icon: Book },
    { name: 'Readings', route: '/astrological-reading', icon: Moon },
    { name: 'Sigils', route: '/create-sigil', icon: Star },
    { name: 'Shop', route: '/shop', icon: ShoppingBag },
    { name: 'Forecast', route: '/forecast', icon: Sun },
    { name: 'Roadmap', route: '/roadmap', icon: Map },
  ];

  return (
    <PageLayout>
      <div className={`pr-6 pl-6 ${loading ? 'loading' : 'loaded'}`}>
        <header className="w-full py-0 flex justify-center items-center pb-12">
          <div className="w-full max-w-screen-lg mx-auto flex justify-center relative">
            <div className="relative">
              <img
                src="/heder-2.webp"
                alt="Hederum"
                className="h-32 object-contain"
              />
            </div>
          </div>
        </header>

        <nav className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 mb-4">
          {navigationItems.map((item, index) => (
            <Link
              key={index}
              to={item.route}
              className="bg-card text-font-muted hover:text-font
                      font-medium py-3 px-2 rounded-lg flex flex-col items-center justify-center
                      transition duration-300 transform hover:scale-105 hover:shadow-glow shadow-mid"
            >
              <item.icon size={24} className="mb-1 text-accent" />
              <span className="text-center text-sm">{item.name}</span>
            </Link>
          ))}
        </nav>

        <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 mb-0">
          <div className="lg:col-span-3 bg-card rounded-lg p-4 shadow-mid hover:shadow-glow transition-shadow duration-300">
            <h2 className="text-lg font-bold mb-3 text-center text-yellow-600">
              Planets Today
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="md:col-span-1 flex items-center justify-center h-full">
                <DynamicMoonPhaseWidget className="w-full max-w-[150px] md:max-w-[200px]" />
              </div>
              <div className="md:col-span-2">
                <OccultAstrologyTheme data={astrologyData} />
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 bg-card rounded-lg p-8 shadow-mid hover:shadow-glow transition-shadow duration-300">
            <h3 className="text-xl font-semibold mb-4">
              Additional Information
            </h3>
            <p>You can add more components or information here.</p>
            {/* Add your additional content here */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;
