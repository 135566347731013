import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from './CartContext';
import './Style/Navbar.css';

function Navbar(maintenance) {
  const { cartCount } = useCart();

  return (
    <nav className="bg-navbar p-3 z-10">
      <div className="container mx-auto flex justify-between items-center z-100">
        <Link to="/" className="text-white font-semibold flex items-center">
          <img src="/heder.webp" alt="Hederum" className="h-10 mr-2" />{' '}
          {/* Added mr-2 for spacing */}
          <span className="text-3xl">Hederum</span>{' '}
          {/* Added text-xl for size, adjust as needed */}
        </Link>
        {maintenance.maintenance ? (
          ''
        ) : (
          <div className="flex items-center space-x-4">
            {/* <Link
              to="/create-sigil"
              className="text-gray-300 hover:text-accent"
            >
              Create Sigil
            </Link>
            <Link to="/roadmap" className="text-gray-300 hover:text-accent">
              Roadmap
            </Link> */}
            <Link to="/about" className="text-gray-300 hover:text-accent">
              About
            </Link>
            {/*<Link to="/astrological-form" className="text-gray-300 hover:text-accent">
            Birthchart
          </Link>
          <Link to="/shop" className="text-gray-300 hover:text-accent">
            Shop
          </Link>
          <div className="border-l-2 h-6"></div>
          <Link
            to="/cart"
            className="relative flex items-center text-gray-300 hover:text-accent"
          >
            <i className="gg-shopping-cart"></i>
            {cartCount > 0 && (
              <span className="absolute bottom-2 left-2 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                {cartCount}
              </span>
            )}
            </Link>*/}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
