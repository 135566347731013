import React, { useState, useEffect } from 'react';
import externalApiService from '../Services/externalApiService';
import astrologicalService from '../Services/astrologicalService';
import DynamicMoonPhaseWidget from '../MoonPhase';
import OccultAstrologyTheme from '../CurrentAstrologyTheme';
import PageLayout from '../PageLayout';

const ForecastPage = () => {
  const [forecast, setForecast] = useState(null);
  const [forecastMessage, setForecastMessage] = useState('');
  const [loadingForecast, setLoadingForecast] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [error, setError] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);

  useEffect(() => {
    const getCsrfToken = () => {
      const name = 'csrftoken=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    };

    const token = getCsrfToken();
    setCsrfToken(token);

    const fetchData = async () => {
      try {
        const forecastData = await externalApiService.getRapidApiAstrology();
        setForecast(forecastData);
        setLoadingForecast(false);

        const message = await astrologicalService.createAstrologicalSuggestions(
          token
        );

        if (message) {
          setForecastMessage(message);
        } else {
          setForecastMessage('No forecast available');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch forecast data');
      } finally {
        setLoadingMessage(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, []);

  if (loadingForecast) {
    return <div className="text-center mt-8">Loading astrological data...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <PageLayout>
      <div className="bg-card p-6 shadow-big">
        <h2 className="text-3xl text-navbar font-bold mb-12 mt-6 flex justify-center">
          Astrological Forecast
        </h2>
        <div className="grid grid-cols-1 xl:grid-cols-11 gap-2">
          <div className="xl:col-span-2 flex flex-col items-center justify-center xl:pl-8">
            <DynamicMoonPhaseWidget className="w-full max-w-[150px] md:max-w-[200px] mb-4" />
          </div>
          <div className="xl:col-span-2 flex justify-center items-center w-full xl:pr-4">
            <div className="w-full md:w-3/4 xl:w-full">
              <OccultAstrologyTheme data={forecast} />
            </div>
          </div>
          <div className="xl:col-span-7 flex flex-col justify-center">
            <h3 className="text-xl font-semibold mb-4">Important Notice</h3>
            <p className="text-sm mb-3">
              Due to variations in geographical locations, some planetary
              positions, especially the Moon's, may differ slightly from what
              you see here. For this reason, we've omitted house placements.
            </p>
            <p className="text-sm mb-3">
              We recommend confirming these signs for your specific location for
              the most accurate reading.
            </p>
            <p className="text-sm">
              This forecast is designed as a helpful guide for your spiritual
              and ritual practices, aiming to support you on your path of
              personal growth and self-improvement.
            </p>
          </div>
        </div>
        <div className="mt-8 text-left">
          {loadingMessage ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              <span className="ml-2">Generating forecast...</span>
            </div>
          ) : (
            <div className="pt-8" style={{ whiteSpace: 'pre-wrap' }}>
              <h2 className="text-2xl font-semibold mb-4 pb-1 pt-2">
                Spiritual Guidance
              </h2>
              {forecastMessage}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default ForecastPage;
