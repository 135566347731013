// src/services/geocodingService.js
import api from './api'; // Assuming you have an api.js file for axios configuration

const externalApiService = {
  getCitySuggestions: async (query) => {
    try {
      const response = await api.get(`/geocode/`, {
        params: { q: query },
      });

      return response.data.results.map((result) => ({
        name: result.formatted,
        lat: result.geometry.lat,
        lng: result.geometry.lng,
        tz_str: result.annotations.timezone.name,
        nation: result.components.country_code.toUpperCase(),
      }));
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
      throw error;
    }
  },

  getCountryCurrency: async () => {
    try {
      const response = await api.get('/get-exchange-rates/');
      return response.data;
    } catch (error) {
      console.error('Error fetching country currency:', error);
      throw error;
    }
  },

  getMoonPhase: async () => {
    try {
      const timestamp = Math.floor(Date.now() / 1000);
      const response = await api.get('/moon-phase/', {
        params: { timestamp },
      });
      return response;
    } catch (error) {
      console.error('Error fetching moon phase:', error);
      throw error;
    }
  },

  getRapidApiAstrology: async () => {
    try {
      const timestamp = Math.floor(Date.now() / 1000);
      const response = await api.get('/rapid-api-astrology/', {
        params: { timestamp },
      });
      //console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching rapid api astrology:', error);
      throw error;
    }
  },
};

export default externalApiService;
