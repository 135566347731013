import React, { useState, useEffect } from 'react';
import externalApiService from './Services/externalApiService';
import MoonPhaseWidget from './MoonPhaseHelper';

const DynamicMoonPhaseWidget = () => {
  const [phase, setPhase] = useState('');
  const [illumination, setIllumination] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMoonPhase = async () => {
      try {
        const response = await externalApiService.getMoonPhase();
        setPhase(response.data.Phase);
        setIllumination(response.data.Illumination);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching moon phase:', error);
        setLoading(false);
      }
    };
    fetchMoonPhase();
  }, []);

  if (loading) {
    return <div className="moon-phase-widget">Loading...</div>;
  }

  return (
    <div className="moon-phase-widget p-4 rounded-lg w-64">
      <div className="flex justify-center mb-4">
        <MoonPhaseWidget
          phase={illumination}
          isWaxing={!phase.toLowerCase().includes('waning')}
          config={{
            shadowColour: 'black',
            lightColour: 'white',
            diameter: 100,
            earthshine: 0.1,
            blur: 3,
          }}
        />
      </div>
      <div className="pt-24">
        <p className="text-center text-lg font-semibold">{phase}</p>
        <p className="text-center text-sm">
          Illumination: {(illumination * 100).toFixed(1)}%
        </p>
      </div>
    </div>
  );
};

export default DynamicMoonPhaseWidget;
