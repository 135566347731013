import React from 'react';
import { motion } from 'framer-motion';
import { GiCrystalBall, GiCardPlay, GiCrystalCluster } from 'react-icons/gi';
import {
  FaBookOpen,
  FaMoon,
  FaMobileAlt,
  FaPeopleArrows,
} from 'react-icons/fa';
import PageLayout from '../PageLayout';

const roadmapData = [
  {
    id: 1,
    title: 'Expand Occult Knowledge',
    description:
      'Deliver more esoteric topics and swap AI-generated content with expert insights',
    icon: <FaBookOpen />,
    color: '#1F2937', // dark gray
    date: 'Q4 2023',
  },
  {
    id: 2,
    title: 'Mystic Calendar',
    description:
      'Unveil moon phases and astrological transits with multiple ritual suggestions',
    icon: <FaMoon />,
    color: '#4b4893', // medium gray
    date: 'Q1 2024',
  },
  {
    id: 3,
    title: 'Tarot Divination',
    description:
      'Introduce booking for personal Tarot readings and launch AI Tarot interpretations',
    icon: <GiCardPlay />,
    color: '#6c1515', // purple
    date: 'Q2 2024',
  },
  {
    id: 4,
    title: 'Coven Connection',
    description:
      'Establish a private community for practitioners to share arcane knowledge',
    icon: <FaPeopleArrows />,
    color: '#20510b', // teal
    date: 'Q3 2024',
  },
  {
    id: 5,
    title: 'Pocket Grimoire',
    description:
      'Release mobile application, bringing occult wisdom to your fingertips',
    icon: <FaMobileAlt />,
    color: '#765a03', // dark blue
    date: 'Q4 2024',
  },
  {
    id: 6,
    title: 'Esoteric Emporium',
    description:
      'Open our curated shop of mystical artifacts and occult supplies',
    icon: <GiCrystalCluster />,
    color: '#79078b', // bright purple
    date: 'Q1 2025',
  },
  {
    id: 7,
    title: 'Arcane Augmentation',
    description:
      'Continually enhance features based on the whispers of our users',
    icon: <GiCrystalBall />,
    color: '#831843', // dark pink
    date: 'Ongoing',
  },
];

const lightenColor = (color, percent) => {
  const num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

const RoadmapPage = () => {
  return (
    <PageLayout>
      <div className="bg-card p-6 shadow-big">
        <h2 className="text-4xl text-navbar font-bold mb-16 mt-12 flex justify-center">
          Our Plans for You
        </h2>
        <div className="relative">
          {/* Central line */}
          <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-gray-600 transform -translate-x-1/2"></div>

          {roadmapData.map((item, index) => (
            <motion.div
              key={item.id}
              className={`flex items-center mb-12 ${
                index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
              }`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div
                className={`w-1/2 ${
                  index % 2 === 0 ? 'pr-8 text-right' : 'pl-8 text-left'
                }`}
              >
                <h3 className="text-xl font-bold text-navbar">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
                {/* <p className="text-sm font-semibold text-gray-500 mt-1">
                    {item.date}
                  </p> */}
              </div>
              <motion.div
                className={`w-16 h-16 rounded-full flex items-center justify-center z-10`}
                style={{
                  background: `linear-gradient(45deg, ${
                    item.color
                  }, ${lightenColor(item.color, 20)})`,
                }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <div className="text-gray-200 text-2xl">{item.icon}</div>
              </motion.div>
              <div className="w-1/2"></div>
            </motion.div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default RoadmapPage;
