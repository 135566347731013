import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export const useGoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: {
        siteSpeedSampleRate: 100,
        cookieFlags: 'SameSite=None;Secure',
      },
    });
  }, []);

  const trackPageView = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
  };

  const trackEvent = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
    console.log(`Event tracked: ${category} - ${action} - ${label}`);
  };

  return { trackPageView, trackEvent };
};
