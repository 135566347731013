import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp } from 'react-icons/fa'; // Social media icons

const CustomShareButtons = ({ url, title }) => {
  const openShareWindow = (shareUrl) => {
    window.open(shareUrl, '_blank');
  };

  return (
    <div className="share-buttons flex space-x-3 pt-4 xs:pt-4 sm:pt-4 md:pt-0 pb-6 xs:pb-6 sm:pb-6 md:pb-0">
      {/* Facebook Share Button */}
      <button
        onClick={() =>
          openShareWindow(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}`
          )
        }
        className="text-facebook"
      >
        <FaFacebook size={32} />
      </button>

      {/* Twitter Share Button */}
      <button
        onClick={() =>
          openShareWindow(
            `https://twitter.com/intent/tweet?url=${encodeURIComponent(
              url
            )}&text=${encodeURIComponent(title)}`
          )
        }
        className="text-twitter"
      >
        <FaTwitter size={32} />
      </button>

      {/* LinkedIn Share Button */}
      <button
        onClick={() =>
          openShareWindow(
            `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
              url
            )}&title=${encodeURIComponent(title)}`
          )
        }
        className="text-linkedin"
      >
        <FaLinkedin size={32} />
      </button>

      {/* WhatsApp Share Button */}
      <button
        onClick={() =>
          openShareWindow(
            `https://api.whatsapp.com/send?text=${encodeURIComponent(
              title + ' ' + url
            )}`
          )
        }
        className="text-whatsapp"
      >
        <FaWhatsapp size={32} />
      </button>
    </div>
  );
};

export default CustomShareButtons;
