const PageLayout = ({ children }) => {
  return (
    <div className="bg-background main-container py-10">
      <div className="container main-content mx-auto px-4 sm:px-8 lg:px-16 xl:px-32">
        <div className="content-area">{children}</div>
      </div>
    </div>
  );
};

export default PageLayout;
