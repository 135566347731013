// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="bg-navbar text-white py-6 z-5">
      <div className="container mx-auto">
        <div className="text-center">
          <p className="text-xs mt-2">
            &copy; 2024 Hederum. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
