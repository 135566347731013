import React, { useEffect, useRef, useState, useMemo } from 'react';
import ReactGA from 'react-ga';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import ExportButton from './ExportButton';
import './Style/SigilCanvas.css';
// Function to add a random offset to a coordinate
const addRandomOffset = (coordinate, range = 0.2) => {
  const offset = (Math.random() * range - range / 2) * 1;
  return coordinate + offset;
};

const sigilForms = {
  formA: {
    name: 'Circular Form',
    generateCoordinates: (randomRange, gridA, gridB, forceShuffle) => {
      const outerLetters = 'ABCDEFGHIJK'.split('');
      const middleLetters = 'LMNOPQRSTU'.split('');
      const innerLetters = 'VWXYZ'.split('');
      const outerRadius = 3;
      const middleRadius = 2;
      const innerRadius = 1;
      const letterCoordinates = {};

      if (!gridA.current) {
        gridA.current = {
          outer: outerLetters,
          middle: middleLetters,
          inner: innerLetters,
        };
      }

      const shuffleArrayFn = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
      };

      const randomizeGridCoordinates = (grid) => {
        const randomizedGrid = { ...grid };
        shuffleArrayFn(randomizedGrid.outer);
        shuffleArrayFn(randomizedGrid.middle);
        shuffleArrayFn(randomizedGrid.inner);
        return randomizedGrid;
      };

      const finalGridCoordinates = forceShuffle
        ? (gridA.current = randomizeGridCoordinates(gridA.current))
        : gridA.current;

      finalGridCoordinates.outer.forEach((letter, index) => {
        const angle = (index / finalGridCoordinates.outer.length) * 2 * Math.PI;
        letterCoordinates[letter] = [
          addRandomOffset(outerRadius, randomRange),
          angle,
        ];
      });

      finalGridCoordinates.middle.forEach((letter, index) => {
        const angle =
          (index / finalGridCoordinates.middle.length) * 2 * Math.PI;
        letterCoordinates[letter] = [
          addRandomOffset(middleRadius, randomRange),
          angle,
        ];
      });

      finalGridCoordinates.inner.forEach((letter, index) => {
        const angle = (index / finalGridCoordinates.inner.length) * 2 * Math.PI;
        letterCoordinates[letter] = [
          addRandomOffset(innerRadius, randomRange),
          angle,
        ];
      });

      return letterCoordinates;
    },
    polarToCartesian: (radius, angle) => {
      return [radius * Math.cos(angle), radius * Math.sin(angle)];
    },
    drawCircle: true,
  },
  formB: {
    name: 'Square Grid Form',
    generateCoordinates: (randomRange, gridA, gridB, forceShuffle) => {
      const letterToNumber = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        I: 9,
        J: 1,
        K: 2,
        L: 3,
        M: 4,
        N: 5,
        O: 6,
        P: 7,
        Q: 8,
        R: 9,
        S: 1,
        T: 2,
        U: 3,
        V: 4,
        W: 5,
        X: 6,
        Y: 7,
        Z: 8,
      };

      if (!gridB.current) {
        gridB.current = {
          1: [-2, 2],
          2: [0, 2],
          3: [2, 2],
          4: [-2, 0],
          5: [0, 0],
          6: [2, 0],
          7: [-2, -2],
          8: [0, -2],
          9: [2, -2],
        };
      }

      const shuffleArrayFn = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
      };

      const randomizeGridCoordinates = (grid) => {
        const keys = Object.keys(grid);
        shuffleArrayFn(keys);
        const randomizedGrid = {};
        keys.forEach((key, index) => {
          randomizedGrid[index + 1] = grid[key];
        });
        return randomizedGrid;
      };

      const finalGridCoordinates = forceShuffle
        ? (gridB.current = randomizeGridCoordinates(gridB.current))
        : gridB.current;

      const letterCoordinates = {};

      for (let letter in letterToNumber) {
        const baseCoordinate = finalGridCoordinates[letterToNumber[letter]];
        letterCoordinates[letter] = [
          addRandomOffset(baseCoordinate[0], randomRange),
          addRandomOffset(baseCoordinate[1], randomRange),
        ];
      }

      return letterCoordinates;
    },
    polarToCartesian: (x, y) => [x, y],
    drawCircle: false,
  },
};

const SigilCanvas = ({
  phrase,
  formType = 'formA',
  randomRange,
  lineWidth,
  outLineWidth,
  image,
}) => {
  const canvasRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(new THREE.PerspectiveCamera(45, 1, 0.1, 100));
  const rendererRef = useRef(null);
  const composerRef = useRef(null);
  const controlsRef = useRef(null);
  const outLineWidthRef = useRef(outLineWidth);
  const flameMaterialRef = useRef(null);

  const gridA = useRef(null);
  const gridB = useRef(null);

  const [shuffleArray, setShuffleArray] = useState(false);
  const [forceUpdateSigil, setForceUpdateSigil] = useState(false);
  const [button, setButton] = useState(false);
  const [controls_enabled] = useState(false);
  const [currentForm, setCurrentForm] = useState(sigilForms[formType]);
  const [storedCoordinatesA, setStoredCoordinatesA] = useState(null);
  const [storedCoordinatesB, setStoredCoordinatesB] = useState(null);
  const [points, setPoints] = useState([]);
  const [texture, setTexture] = useState(
    new THREE.TextureLoader().load('./flame.avif')
  );

  const circleRef = useRef(null);
  const squareRef = useRef(null);
  const sigilElementsRef = useRef([]);

  const initialCameraPosition = new THREE.Vector3(0, 0, 10);
  const initialCameraRotation = new THREE.Euler(0, 0, 0);

  const resetCamera = () => {
    cameraRef.current.position.copy(initialCameraPosition);
    cameraRef.current.rotation.copy(initialCameraRotation);
    cameraRef.current.zoom = 1;
    cameraRef.current.updateProjectionMatrix();
  };

  const toggleShuffleArray = () => {
    setShuffleArray((prevState) => !prevState);
    setForceUpdateSigil(true);
  };

  const textureLoader = new THREE.TextureLoader();

  useEffect(() => {
    if (image) {
      const loader = new THREE.TextureLoader();
      loader.load(image, (texture) => {
        setTexture(texture);
        if (flameMaterialRef.current) {
          flameMaterialRef.current.uniforms.flameTexture.value = texture;
          flameMaterialRef.current.uniforms.flameTexture.needsUpdate = true;
        }
      });
    }
  }, [image]);

  const vertexShader = `
    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  const fragmentShader = `
    uniform float time;
    uniform sampler2D flameTexture;
    varying vec2 vUv;

    void main() {
        vec2 uv = vUv;
        uv.x = mod(uv.x + time * 0.01, 1.0);
        uv.y = mod(uv.y + time * 0.01, 1.0);
        
        vec4 color = texture2D(flameTexture, uv);
        gl_FragColor = color;
    }
  `;

  // Initialize flameMaterialRef only once
  if (!flameMaterialRef.current) {
    flameMaterialRef.current = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        time: { value: 0.0 },
        flameTexture: { value: texture },
      },
      transparent: true,
    });
  }

  const drawCircle = () => {
    if (!circleRef.current) {
      const circleRadius = 3.7; // Adjusted to be slightly larger than the outer radius
      const tubeRadius = outLineWidth;
      const geometry = new THREE.TorusGeometry(
        circleRadius,
        tubeRadius,
        30,
        100
      );
      circleRef.current = new THREE.Mesh(geometry, flameMaterialRef.current);
      sceneRef.current.add(circleRef.current);
    } else {
      // Check if `outLineWidth` has changed
      if (outLineWidth !== outLineWidthRef.current) {
        // Update the geometry
        const circleRadius = 3.7;
        const tubeRadius = outLineWidth;
        const newGeometry = new THREE.TorusGeometry(
          circleRadius,
          tubeRadius,
          30,
          100
        );
        circleRef.current.geometry.dispose(); // Dispose of the old geometry
        circleRef.current.geometry = newGeometry; // Assign new geometry
        outLineWidthRef.current = outLineWidth; // Update the ref with the new width
      }
    }
  };

  const removeCircle = () => {
    if (circleRef.current) {
      sceneRef.current.remove(circleRef.current);
      circleRef.current = null;
    }
  };

  const drawSquare = () => {
    if (!squareRef.current) {
      squareRef.current = new THREE.Group(); // Create a group to hold the edges

      const squareSize = 6; // Total width and height of the square
      const edgeThickness = outLineWidth; // Thickness of the square's edges
      const edgeLength = squareSize; // Length of each edge

      // Edge definitions, positioned at the midpoints and rotated correctly
      const edges = [
        { position: [-3, 0, 0], rotation: [0, 0, 0] }, // Top horizontal
        { position: [3, 0 / 2, 0], rotation: [0, 0, 0] }, // Bottom horizontal
        { position: [0, -3, 0], rotation: [0, 0, Math.PI / 2] }, // Left vertical
        { position: [0, 3, 0], rotation: [0, 0, Math.PI / 2] }, // Right vertical
      ];

      edges.forEach((edge) => {
        const geometry = new THREE.CylinderGeometry(
          edgeThickness,
          edgeThickness,
          edgeLength,
          32
        );
        const cylinder = new THREE.Mesh(geometry, flameMaterialRef.current);
        cylinder.position.set(...edge.position);
        cylinder.rotation.set(...edge.rotation);
        squareRef.current.add(cylinder);
      });

      sceneRef.current.add(squareRef.current);
    }

    if (outLineWidth !== outLineWidthRef) {
      while (squareRef.current.children.length > 0) {
        const child = squareRef.current.children[0];
        squareRef.current.remove(child);
        child.geometry.dispose();
        child.material.dispose();
      }
      const squareSize = 6; // Total width and height of the square
      const edgeThickness = outLineWidth; // Thickness of the square's edges
      const edgeLength = squareSize; // Length of each edge

      // Edge definitions, positioned at the midpoints and rotated correctly
      const edges = [
        { position: [-3, 0, 0], rotation: [0, 0, 0] }, // Top horizontal
        { position: [3, 0 / 2, 0], rotation: [0, 0, 0] }, // Bottom horizontal
        { position: [0, -3, 0], rotation: [0, 0, Math.PI / 2] }, // Left vertical
        { position: [0, 3, 0], rotation: [0, 0, Math.PI / 2] }, // Right vertical
      ];

      edges.forEach((edge) => {
        const geometry = new THREE.CylinderGeometry(
          edgeThickness,
          edgeThickness,
          edgeLength + outLineWidth,
          32
        );
        const cylinder = new THREE.Mesh(geometry, flameMaterialRef.current);
        cylinder.position.set(...edge.position);
        cylinder.rotation.set(...edge.rotation);
        squareRef.current.add(cylinder);
      });

      outLineWidthRef.current = outLineWidth;
    }
  };

  const removeSquare = () => {
    if (squareRef.current) {
      sceneRef.current.remove(squareRef.current);
      squareRef.current = null;
    }
  };

  const cleanPhrase = (phrase, formType) => {
    const vowels = 'aeiou';
    return phrase
      .toLowerCase()
      .replace(/ /g, '')
      .split('')
      .filter((char, index, self) => {
        return vowels.indexOf(char) === -1 && self.indexOf(char) === index;
      })
      .join('');
  };

  const updateSigil = (phrase, generateNew = true) => {
    let letterCoordinates =
      formType === 'formA' ? storedCoordinatesA : storedCoordinatesB;
    if (generateNew) {
      letterCoordinates = currentForm.generateCoordinates(
        randomRange,
        gridA,
        gridB,
        forceUpdateSigil
      );
      formType === 'formA'
        ? setStoredCoordinatesA(letterCoordinates)
        : setStoredCoordinatesB(letterCoordinates);
    }
    const cleanedPhrase = cleanPhrase(phrase, formType);
    const newPoints = cleanedPhrase
      .split('')
      .map((char) => {
        if (letterCoordinates[char.toUpperCase()]) {
          const [x, y] = currentForm.polarToCartesian(
            ...letterCoordinates[char.toUpperCase()]
          );
          return new THREE.Vector3(x, y, 0);
        }
        return null;
      })
      .filter(Boolean);

    setPoints(newPoints);

    // Handle drawing or removing shapes
    if (newPoints.length > 0) {
      if (currentForm.name === 'Circular Form') {
        drawCircle();
        removeSquare(); // Make sure to remove the square if the circle is drawn
      } else if (currentForm.name === 'Square Grid Form') {
        drawSquare();
        removeCircle(); // Remove the circle if the square is drawn
      }
    } else {
      removeCircle();
      removeSquare();
    }

    // Remove excess elements
    while (sigilElementsRef.current.length > newPoints.length) {
      const element = sigilElementsRef.current.pop();
      sceneRef.current.remove(element);
    }

    // Update or add elements
    for (let i = 0; i < newPoints.length; i++) {
      if (i === sigilElementsRef.current.length) {
        // Add new element
        const element = new THREE.Group();
        sigilElementsRef.current.push(element);
        sceneRef.current.add(element);
      }

      const element = sigilElementsRef.current[i];
      element.clear();

      if (i < newPoints.length - 1) {
        // Add line
        const start = newPoints[i];
        const end = newPoints[i + 1];
        const direction = new THREE.Vector3().subVectors(end, start);
        const length = direction.length();
        const geometry = new THREE.CylinderGeometry(
          lineWidth,
          lineWidth,
          length + lineWidth,
          8
        );
        const cylinder = new THREE.Mesh(geometry, flameMaterialRef.current);
        cylinder.position.copy(
          new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5)
        );
        cylinder.quaternion.setFromUnitVectors(
          new THREE.Vector3(0, 1, 0),
          direction.normalize()
        );
        element.add(cylinder);
      }

      if (i === 0) {
        if (newPoints.length > 1) {
          const direction = new THREE.Vector3()
            .subVectors(newPoints[1], newPoints[0])
            .normalize();
          const offsetDirection = new THREE.Vector3()
            .copy(direction)
            .multiplyScalar(-0.13);
          const dotGeometry = new THREE.TorusGeometry(
            0.12 + lineWidth / 2,
            lineWidth,
            38,
            60
          );
          const dotMesh = new THREE.Mesh(dotGeometry, flameMaterialRef.current);
          dotMesh.position.copy(newPoints[0]).add(offsetDirection);
          element.add(dotMesh);
        } else {
          // If there's only one point, no need for directional offset
          const dotGeometry = new THREE.TorusGeometry(
            0.12 + lineWidth / 2,
            lineWidth,
            38,
            60
          );
          const dotMesh = new THREE.Mesh(dotGeometry, flameMaterialRef.current);
          dotMesh.position.copy(newPoints[0]);
          element.add(dotMesh);
        }
      }

      if (i === newPoints.length - 1 && newPoints.length > 1) {
        const lastPoint = newPoints[i];
        const prevPoint = newPoints[i - 1];
        const direction = new THREE.Vector3()
          .subVectors(lastPoint, prevPoint)
          .normalize();
        const perpendicularDirection = new THREE.Vector3().crossVectors(
          direction,
          new THREE.Vector3(0, 0, 1)
        );
        if (perpendicularDirection.lengthSq() > 0) {
          perpendicularDirection.normalize().multiplyScalar(0.25);
          const geometry = new THREE.CylinderGeometry(
            lineWidth,
            lineWidth,
            perpendicularDirection.length() * 2,
            8
          );
          const endPoint = new THREE.Vector3().addVectors(
            lastPoint,
            perpendicularDirection
          );
          const endLine = new THREE.Mesh(geometry, flameMaterialRef.current);
          endLine.position.copy(lastPoint);
          endLine.lookAt(endPoint);
          endLine.rotateX(Math.PI / 2);
          element.add(endLine);
        } else {
          console.error('Invalid perpendicular direction, length is zero.');
        }
      }
    }

    setButton(newPoints.length > 0);
  };

  const updateLineWidth = () => {
    points.forEach((start, i) => {
      const element = sigilElementsRef.current[i];
      element.clear(); // Clear previous elements

      if (i < points.length - 1) {
        const end = points[i + 1];
        const direction = new THREE.Vector3().subVectors(end, start);
        const length = direction.length();
        const geometry = new THREE.CylinderGeometry(
          lineWidth,
          lineWidth,
          length,
          8
        );
        const cylinder = new THREE.Mesh(geometry, flameMaterialRef.current);
        cylinder.position.copy(
          new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5)
        );
        cylinder.quaternion.setFromUnitVectors(
          new THREE.Vector3(0, 1, 0),
          direction.normalize()
        );
        element.add(cylinder);

        if (i === 0) {
          // Add start dot, size scales with lineWidth
          if (points.length > 1) {
            const offsetDirection = new THREE.Vector3()
              .copy(direction)
              .multiplyScalar(-0.13);
            const dotGeometry = new THREE.TorusGeometry(
              0.12 + lineWidth / 2,
              lineWidth
            );
            const dotMesh = new THREE.Mesh(
              dotGeometry,
              flameMaterialRef.current
            );
            dotMesh.position.copy(points[0]).add(offsetDirection);
            element.add(dotMesh);
          } else {
            const dotGeometry = new THREE.TorusGeometry(
              0.12 + lineWidth / 2,
              lineWidth
            );
            const dotMesh = new THREE.Mesh(
              dotGeometry,
              flameMaterialRef.current
            );
            dotMesh.position.copy(points[0]);
            element.add(dotMesh);
          }
        }
      } else if (points.length === 1) {
        // Add start dot, size scales with lineWidth
        if (points.length > 1) {
          const direction = new THREE.Vector3()
            .subVectors(points[1], points[0])
            .normalize();
          const offsetDirection = new THREE.Vector3()
            .copy(direction)
            .multiplyScalar(-0.13);
          const dotGeometry = new THREE.TorusGeometry(
            0.12 + lineWidth / 2,
            lineWidth
          );
          const dotMesh = new THREE.Mesh(dotGeometry, flameMaterialRef.current);
          dotMesh.position.copy(points[0]).add(offsetDirection);
          element.add(dotMesh);
        } else {
          const dotGeometry = new THREE.TorusGeometry(
            0.12 + lineWidth / 2,
            lineWidth
          );
          const dotMesh = new THREE.Mesh(dotGeometry, flameMaterialRef.current);
          dotMesh.position.copy(points[0]);
          element.add(dotMesh);
        }
      } else if (i === points.length - 1 && points.length > 1) {
        const lastPoint = points[i];
        const prevPoint = points[i - 1];
        const direction = new THREE.Vector3()
          .subVectors(lastPoint, prevPoint)
          .normalize();
        const perpendicularDirection = new THREE.Vector3().crossVectors(
          direction,
          new THREE.Vector3(0, 0, 1)
        );
        if (perpendicularDirection.lengthSq() > 0) {
          perpendicularDirection.normalize().multiplyScalar(0.25);
          const geometry = new THREE.CylinderGeometry(
            lineWidth,
            lineWidth,
            perpendicularDirection.length() * 2,
            8
          );
          const endPoint = new THREE.Vector3().addVectors(
            lastPoint,
            perpendicularDirection
          );
          const endLine = new THREE.Mesh(geometry, flameMaterialRef.current);
          endLine.position.copy(lastPoint);
          endLine.lookAt(endPoint);
          endLine.rotateX(Math.PI / 2);
          element.add(endLine);
        } else {
          console.error('Invalid perpendicular direction, length is zero.');
        }
      }
    });
  };

  useEffect(() => {
    setCurrentForm(sigilForms[formType]);
    updateSigil(phrase);
  }, [formType]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (!rendererRef.current) {
      rendererRef.current = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      rendererRef.current.setPixelRatio(window.devicePixelRatio);
      canvas.appendChild(rendererRef.current.domElement);
    }

    cameraRef.current.position.copy(initialCameraPosition);
    cameraRef.current.rotation.copy(initialCameraRotation);

    if (!composerRef.current) {
      composerRef.current = new EffectComposer(rendererRef.current);
      composerRef.current.addPass(
        new RenderPass(sceneRef.current, cameraRef.current)
      );
    }

    if (controls_enabled) {
      controlsRef.current = new OrbitControls(
        cameraRef.current,
        rendererRef.current.domElement
      );
      controlsRef.current.enableDamping = true;
      controlsRef.current.dampingFactor = 0.25;
      controlsRef.current.enableZoom = true;
    }

    const handleResize = () => {
      if (canvas) {
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        rendererRef.current.setSize(width, height);
        cameraRef.current.aspect = width / height;
        cameraRef.current.updateProjectionMatrix();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const animate = () => {
      requestAnimationFrame(animate);
      if (controls_enabled && controlsRef.current) controlsRef.current.update();
      flameMaterialRef.current.uniforms.time.value += 0.05;
      composerRef.current.render();
    };
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    updateSigil(phrase, true);
  }, [phrase, randomRange, currentForm]);

  useEffect(() => {
    updateSigil(phrase, forceUpdateSigil);
    setForceUpdateSigil(false);
  }, [forceUpdateSigil]);

  useEffect(() => {
    updateLineWidth(); // Adjust the line width without changing the sigil structure
  }, [lineWidth]);

  useEffect(() => {
    if (outLineWidth !== outLineWidthRef.current)
      if (formType === 'formA') drawCircle();
      else drawSquare();
  }, [outLineWidth]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="sigil-container flex flex-col items-center w-full h-full">
        <div
          className="w-full h-64 sm:h-80 md:h-96 lg:h-128 xl:h-144"
          ref={canvasRef}
        ></div>
      </div>
      <div className="flex flex-row items-center justify-center mt-4 space-x-16">
        {button && (
          <button
            onClick={toggleShuffleArray}
            className="mt-4 sm:mt-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Shuffle
          </button>
        )}
        {button && (
          <ExportButton
            renderer={rendererRef.current}
            camera={cameraRef.current}
            scene={sceneRef.current}
            resetCamera={resetCamera}
          />
        )}
      </div>
    </>
  );
};

export default SigilCanvas;
