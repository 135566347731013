// src/services/maintenanceService.js
import api from './api';

const maintenanceService = {
  getMaintenanceStatus: async () => {
    try {
      const response = await api.get('/maintenance-status/');
      return response.data;
    } catch (error) {
      console.error('Error fetching maintenance status:', error);
      // Return a default object in case of error
      return {
        maintenance_mode: true,
        allowed: false,
        error: error.message,
      };
    }
  },
};

export default maintenanceService;
