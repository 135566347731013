import React, { useEffect, useRef } from 'react';

const drawPlanetPhase = (containerEl, phase, isWaxing, config) => {
  'use strict';

  function calcInner(outerDiameter, semiPhase) {
    var innerRadius,
      absPhase = Math.abs(semiPhase),
      n = ((1 - absPhase) * outerDiameter) / 2 || 0.01;

    innerRadius = n / 2 + (outerDiameter * outerDiameter) / (8 * n);

    return {
      d: innerRadius * 2,
      o:
        semiPhase > 0
          ? outerDiameter / 2 - n
          : -2 * innerRadius + outerDiameter / 2 + n,
    };
  }

  function setCss(el, props) {
    Object.assign(el.style, props);
  }

  function drawDiscs(outer, inner, blurSize) {
    var blurredDiameter, blurredOffset;
    setCss(outer.box, {
      position: 'absolute',
      height: outer.diameter + 'px',
      width: outer.diameter + 'px',
      border: '1px solid black',
      backgroundColor: outer.colour,
      borderRadius: outer.diameter / 2 + 'px',
      overflow: 'hidden',
    });

    blurredDiameter = inner.diameter - blurSize;
    blurredOffset = inner.offset + blurSize / 2;

    setCss(inner.box, {
      position: 'absolute',
      backgroundColor: inner.colour,
      borderRadius: blurredDiameter / 2 + 'px',
      height: blurredDiameter + 'px',
      width: blurredDiameter + 'px',
      left: blurredOffset + 'px',
      top: (outer.diameter - blurredDiameter) / 2 + 'px',
      boxShadow:
        '0px 0px ' + blurSize + 'px ' + blurSize + 'px ' + inner.colour,
      opacity: inner.opacity,
    });
  }

  function setPhase(outerBox, phase, isWaxing, config) {
    var innerBox = document.createElement('div'),
      outerColour,
      innerColour,
      innerVals;

    outerBox.appendChild(innerBox);

    if (phase < 0.5) {
      outerColour = config.lightColour;
      innerColour = config.shadowColour;
      if (isWaxing) {
        phase *= -1;
      }
    } else {
      outerColour = config.shadowColour;
      innerColour = config.lightColour;
      phase = 1 - phase;
      if (!isWaxing) {
        phase *= -1;
      }
    }

    innerVals = calcInner(config.diameter, phase * 2);

    drawDiscs(
      {
        box: outerBox,
        diameter: config.diameter,
        colour: outerColour,
      },
      {
        box: innerBox,
        diameter: innerVals.d,
        colour: innerColour,
        offset: innerVals.o,
        opacity: 1 - config.earthshine,
      },
      config.blur
    );
  }

  var defaultConfig = {
    shadowColour: 'black',
    lightColour: 'white',
    diameter: 100,
    earthshine: 0.1,
    blur: 3,
  };

  config = Object.assign(defaultConfig, config);

  setPhase(containerEl, phase, isWaxing, config);
};

const MoonPhaseWidget = ({ phase, isWaxing, config }) => {
  const moonRef = useRef(null);

  useEffect(() => {
    if (moonRef.current) {
      drawPlanetPhase(moonRef.current, phase, isWaxing, config);
    }
  }, [phase, isWaxing, config]);

  return (
    <div
      ref={moonRef}
      style={{
        position: 'relative',
        width: config.diameter,
        height: config.diameter,
      }}
    ></div>
  );
};

export default MoonPhaseWidget;
